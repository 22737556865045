import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './style/generic.scss';
import './assets/font/iconfont.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import EventBus from './utils/eventBus';
import axios from 'axios';


// axios.defaults.baseURL = 'http://39.100.78.156:29527/website'; // 设置根请求路径
axios.defaults.baseURL = 'http://202.85.217.70:29527/website'; // 设置根请求路径
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
