import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import( '../view/main.vue'),
    redirect: '/home',
    children:[
      {
        path: '/home',
        name:'home',
        component: () => import('../components/Home.vue'),
        meta: {
          title: '智牧科技',
        },
      },
      {
        path: '/news',
        name:'news',
        component: () => import('../components/NewsUpdates.vue'),
        meta: {
          title: '新闻动态',
        },
      },
      {
        path: '/aboutus',
        name:'aboutus',
        component: () => import('../components/Aboutus.vue'),
        meta: {
          title: '关于我们',
        },
      },
      {
        path: '/breed-app',
        name:'breedapp',
        component: () => import('../components/product/BreedApp.vue'),
        meta: {
          title: '智慧养殖APP',
        },
      },
      {
        path: '/pasture-manage',
        name:'pasturemanage',
        component: () => import('../components/product/PastureManage.vue'),
        meta: {
          title: '智慧牧场管理系统',
        },
      },
      {
        path: '/data-platform',
        name:'dataplatform',
        component: () => import('../components/product/DataPlatform.vue'),
        meta: {
          title: '溯源大数据平台',
        },
      },
      {
        path: '/pasture-hardware',
        name:'pasturehardware',
        component: () => import('../components/product/PastureHardware.vue'),
        meta: {
          title: '牧场智能硬件',
        },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || '智牧科技';
  })
})
export default router
